import {
  isProductionEnvironment,
  isProductionPublicContext,
} from '@squareup/dex-utils-environment';

function isDocsAdminToolsEnabled() {
  return !isProductionPublicContext() || !isProductionEnvironment();
}

export { isDocsAdminToolsEnabled };
