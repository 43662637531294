import { Box, Paragraph10 } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import styles from './docs-banner.module.css';

interface DocsBannerProps {
  variant: 'green' | 'purple' | 'red' | 'blue';
}

const DocsBanner: FC<PropsWithChildren<DocsBannerProps>> = ({
  children,
  variant = 'green',
}) => {
  return (
    <Box
      testId="docs-debug-banner"
      className={clsx(styles.banner, styles[variant])}
      border={{ line: '10' }}
    >
      <Paragraph10 weight="medium">{children}</Paragraph10>
    </Box>
  );
};

export { DocsBanner };
