import { ParsedUrlQuery } from 'querystring';

import {
  setIsPreview,
  setNavSet,
  setPageInView,
  setReleaseTrain,
} from '@squareup/dex-data-dex-tech-docs-view-state-slices';
import { getDeveloperApi } from '@squareup/dex-data-shared-developer-api';
import { setGlobalError } from '@squareup/dex-data-shared-main-data-slices';
// Allowing us to break the boundary for now to get this working
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseFrontmatter } from '@squareup/dex-feature-markdown';
import { DocsStoreAction } from '@squareup/dex-shell-docs-core';
import { SliceError, StoreStateNode } from '@squareup/dex-types-data-state';
import { Domain } from '@squareup/dex-types-shared-app-launch';
import {
  DocPage,
  DocType,
  MarkdownBlock,
} from '@squareup/dex-types-shared-docs';
import {
  stateErrorToCustomError,
  publishActionableError,
} from '@squareup/dex-utils-error';
import { INTERNAL_SERVER_ERROR_CODE } from '@squareup/dex-utils-shared-http-status';

const MAX_REDIRECTS = 5;

// getRequirementDomainId parses the content of a docpage
// to retrieve a domain id from its frontmatter. It returns null
// when no domain id is found.
const getRequirementDomainId = (docPage: DocPage) => {
  const filteredContent = docPage.content?.filter((content) => {
    return content.type === 'markdown';
  });

  // Assume the first content block is the only one with the requirement domain id
  if (filteredContent.length > 0) {
    // eslint-disable-next-line no-undef
    const markdown = filteredContent[0] as MarkdownBlock;
    const frontmatter = parseFrontmatter({ markdown: markdown.markdown });
    if (
      frontmatter?.pageType === 'requirements' &&
      frontmatter.requirementDomainId
    ) {
      return frontmatter.requirementDomainId;
    }
  }

  return null;
};

const fetchRequirementsDataForPage: (options: {
  domainId: string;
  preview?: boolean | undefined;
  contentfulEnvironment?: string | undefined;
  dispatch: Parameters<DocsStoreAction<void>>[0];
  getState: Parameters<DocsStoreAction<void>>[1];
}) => Promise<{
  domain: Domain | undefined;
  error: SliceError | undefined;
}> = async ({
  domainId,
  preview,
  contentfulEnvironment,
  dispatch,
  getState,
}) => {
  const requirementsDomainEndpoint = getDeveloperApi().endpoints.getDomain;
  const requirementsInfo = {
    domain_id: domainId,
    preview,
    environment: contentfulEnvironment,
  };

  await dispatch(requirementsDomainEndpoint.initiate(requirementsInfo));

  const { data, error } = requirementsDomainEndpoint.select(requirementsInfo)(
    getState()
  );

  let storeStateError: StoreStateNode<unknown>['error'] = undefined;
  if (error) {
    // There was some kind of error in an upstream service.
    // We need to convert the error to a custom store node
    // error type to set on the parsed view state.
    storeStateError = stateErrorToCustomError(
      error,
      'Error fetching requirements data for page.',
      `Domain Id: ${domainId}`
    );
  } else if (!data) {
    storeStateError = {
      data: 'requirements data undefined',
      error: `No requirements content found for domain id: ${domainId}`,
      originalStatus: INTERNAL_SERVER_ERROR_CODE,
      status: 'CUSTOM_ERROR',
    };
  }

  return { domain: data, error: storeStateError };
};

const loadDocPageInView: (options: {
  query: ParsedUrlQuery;
  preview?: boolean | undefined;
  releaseTrain?: string | undefined;
  contentfulEnvironment?: string | undefined;
  stateless?: boolean | undefined;
  cookie?: string | undefined;
}) => DocsStoreAction<void> =
  ({
    query,
    preview = false,
    releaseTrain,
    contentfulEnvironment,
    stateless = false,
    cookie,
  }) =>
  async (dispatch, getState) => {
    const docType: DocType = DocType.DocPage;

    const querySlug = query['slug'];
    const queryInfo = {
      slug:
        typeof querySlug === 'string' ? querySlug : querySlug?.join('/') || '',
      type: docType,
      preview,
      releaseTrain,
      environment: contentfulEnvironment,
      cookie,
    };

    const docPageEndpoint = getDeveloperApi().endpoints.getDocPage;

    await dispatch(docPageEndpoint.initiate(queryInfo));

    const { data: pageData, error: pageError } = docPageEndpoint.select(
      queryInfo
    )(getState());

    let storeStateError: StoreStateNode<unknown>['error'] = undefined;

    // Ignore the error for now if stateless
    if (pageError && !stateless) {
      // There was some kind of error in an upstream service.
      // We need to convert the error to a custom store node
      // error type to set on the parsed view state.
      storeStateError = stateErrorToCustomError(
        pageError,
        'Error fetching doc page.',
        `Slug: ${queryInfo.slug} - Type: ${queryInfo.type}`
      );
    } else if (!pageData) {
      storeStateError = {
        data: 'data undefined',
        error: 'No doc content return in from api.',
        originalStatus: INTERNAL_SERVER_ERROR_CODE,
        status: 'CUSTOM_ERROR',
      };
    }

    if (storeStateError) {
      publishActionableError(storeStateError);
      await dispatch(setGlobalError(storeStateError));
      await dispatch(setPageInView(storeStateError));
      return;
    }

    let domainData: Domain | undefined;
    if (pageData?.type === 'doc-page') {
      const domainId = getRequirementDomainId(pageData);
      if (domainId) {
        const { domain: requirementsDomain, error: requirementsError } =
          await fetchRequirementsDataForPage({
            domainId,
            preview: Boolean(preview || releaseTrain),
            dispatch,
            getState,
          });

        // Ignore errors for now if stateless
        if (requirementsError && !stateless) {
          publishActionableError(requirementsError);
          await dispatch(setGlobalError(requirementsError));
          await dispatch(setPageInView(requirementsError));
          return;
        }

        domainData = requirementsDomain;
      }
    }

    // Don't bother checking or persisting state. This is purely to
    // warm the RTKQ cache
    if (stateless) {
      return;
    }

    await dispatch(
      setPageInView({
        page: pageData || null,
        requirementsDomain: domainData || null,
      })
    );
    await dispatch(setIsPreview({ isPreview: preview }));
  };

const loadNav: (options: {
  preview: boolean;
  releaseTrain?: string | undefined;
  contentfulEnvironment?: string | undefined;
  cookie?: string | undefined;
}) => DocsStoreAction<void> =
  ({ preview, releaseTrain, contentfulEnvironment, cookie }) =>
  async (dispatch, getState) => {
    const docsNavSetEndpoint = getDeveloperApi().endpoints.getDocsNavSet;

    const queryInfo = {
      preview,
      releaseTrain,
      environment: contentfulEnvironment,
      cookie,
    };
    await dispatch(docsNavSetEndpoint.initiate(queryInfo));
    const { data, error } = docsNavSetEndpoint.select(queryInfo)(getState());

    let storeStateError: StoreStateNode<unknown>['error'] = undefined;

    if (error) {
      // There was some kind of error in an upstream service.
      // We need to convert the error to a custom store node
      // error type to set on the parsed view state.
      storeStateError = stateErrorToCustomError(
        error,
        'Error fetching docs nav set.'
      );
    } else if (!data) {
      storeStateError = {
        data: 'data undefined',
        error: 'No doc nav set returned from api.',
        originalStatus: INTERNAL_SERVER_ERROR_CODE,
        status: 'CUSTOM_ERROR',
      };
    }

    if (storeStateError) {
      publishActionableError(storeStateError);
      await dispatch(setGlobalError(storeStateError));
      await dispatch(setNavSet(storeStateError));
      return;
    }

    await dispatch(setNavSet({ value: data || null }));
  };

const followClientSideRedirects: (options: {
  slug: string;
  preview: boolean;
  releaseTrain?: string | undefined;
  contentfulEnvironment?: string | undefined;
}) => DocsStoreAction<void> =
  ({ slug, preview, releaseTrain, contentfulEnvironment }) =>
  async (dispatch, getState) => {
    let nextRedirect = slug;
    for (let i = 0; i < MAX_REDIRECTS; i++) {
      await dispatch(
        loadDocPageInView({
          query: { slug: nextRedirect },
          preview,
          releaseTrain,
          contentfulEnvironment,
        })
      );
      const { page, error } = getState().docsShellViewState?.pageInView || {};
      if (error) {
        return;
      }

      if (page?.type !== 'redirect') {
        return;
      }

      nextRedirect = page.redirectTo;
    }

    const storeStateError: StoreStateNode<unknown>['error'] = {
      error: `Max redirects followed for slug ${slug}. Current redirect is '${nextRedirect}'`,
      originalStatus: INTERNAL_SERVER_ERROR_CODE,
      status: 'CUSTOM_ERROR',
    };
    await dispatch(setPageInView(storeStateError));
  };

const loadDiffPageContent: (options: {
  query: ParsedUrlQuery;
  preview: boolean;
  releaseTrain?: string | undefined;
  contentfulEnvironment?: string | undefined;
}) => DocsStoreAction<void> =
  ({ query, preview, releaseTrain, contentfulEnvironment }) =>
  async (dispatch, getState) => {
    await dispatch(
      loadDocPageInView({ query, preview, releaseTrain, contentfulEnvironment })
    );
    const { page } = getState().docsShellViewState?.pageInView || {};
    if (page?.type === 'redirect') {
      await dispatch(
        setPageInView({
          error: `'diff' pages cannot handle pages of type 'redirect'. The redirect attempted to go to '/docs/diff/${page.redirectTo}'. Please load up that page instead`,
          status: 'CUSTOM_ERROR',
          originalStatus: 400,
        })
      );
    }

    // Ensure the preview state is always false at the end
    await dispatch(setIsPreview({ isPreview: false }));
  };

const loadReleaseTrain: (options: {
  releaseTrain: string;
}) => DocsStoreAction<void> =
  ({ releaseTrain }) =>
  async (dispatch, getState) => {
    const docsReleaseTrainEndpoint =
      getDeveloperApi().endpoints.getDocsReleaseTrain;
    const queryInfo = {
      slug: releaseTrain,
    };

    await dispatch(docsReleaseTrainEndpoint.initiate(queryInfo));
    const { data, error } = docsReleaseTrainEndpoint.select(queryInfo)(
      getState()
    );

    let storeStateError: StoreStateNode<unknown>['error'] = undefined;

    if (error) {
      // There was some kind of error in an upstream service.
      // We need to convert the error to a custom store node
      // error type to set on the parsed view state.
      storeStateError = stateErrorToCustomError(
        error,
        'Error fetching release train.',
        `Release train: ${releaseTrain}`
      );
    } else if (!data) {
      storeStateError = {
        data: 'data undefined',
        error: 'No release train data returned in from api.',
        originalStatus: INTERNAL_SERVER_ERROR_CODE,
        status: 'CUSTOM_ERROR',
      };
    }

    if (storeStateError) {
      publishActionableError(storeStateError);
      await dispatch(setGlobalError(storeStateError));
      await dispatch(setReleaseTrain(storeStateError));
      return;
    }

    await dispatch(setReleaseTrain({ inView: true, state: data || null }));
  };

const getReleaseTrain: (options: {
  releaseTrain: string;
}) => DocsStoreAction<void> =
  ({ releaseTrain }) =>
  async (dispatch, getState) => {
    const queryInfo = {
      slug: releaseTrain,
    };
    const docsReleaseTrainEndpoint =
      getDeveloperApi().endpoints.getDocsReleaseTrain;
    await dispatch(docsReleaseTrainEndpoint.initiate(queryInfo));
    const { data, error } = docsReleaseTrainEndpoint.select(queryInfo)(
      getState()
    );

    if (!error) {
      await dispatch(setReleaseTrain({ inView: true, state: data || null }));
    }
  };

export {
  loadDocPageInView,
  loadNav,
  followClientSideRedirects,
  loadDiffPageContent,
  loadReleaseTrain,
  getReleaseTrain,
};
