import {
  DocsNavSetPayload,
  DocsPageInViewPayload,
  setIsPreview,
} from '@squareup/dex-data-dex-tech-docs-view-state-slices';
import {
  toggleMobileNavIsOpen,
  toggleLeftNavIsCollapsed,
} from '@squareup/dex-data-shared-main-data-slices';
import {
  isDocsAdminToolsEnabled,
  PreviewHandler,
} from '@squareup/dex-feature-docs-page';
import { ErrorPage } from '@squareup/dex-feature-shared-main-error-page';
import { HeaderContainer } from '@squareup/dex-feature-shared-main-header-container';
import { fence } from '@squareup/dex-feature-shared-markdown-fence';
import {
  MarkdownContextProvider,
  useMarkdownContext,
} from '@squareup/dex-markdown-context-provider';
import { DocsStore } from '@squareup/dex-shell-docs-core';
import {
  useDocsDispatch,
  usePrefetchPageOnMouseOver,
} from '@squareup/dex-store-access-dex-tech-docs-actions';
import { useSharedDispatch } from '@squareup/dex-store-access-shared-main-dispatch';
import {
  useSelectMobileNavState,
  useSelectLeftNavState,
} from '@squareup/dex-store-access-shared-main-selectors';
import { SliceError } from '@squareup/dex-types-data-state';
import { DocsQueryParams, NavSet } from '@squareup/dex-types-shared-docs';
import { Footer, LinkContextProvider } from '@squareup/dex-ui';
import {
  TopNav,
  LeftNavContainer,
  MobileNav,
} from '@squareup/dex-ui-dex-tech-docs-docs-nav';
import { DocsPageAppLayout } from '@squareup/dex-ui-dex-tech-docs-page-layout';
import {
  isDocsPageHref,
  makeDocUrlRelative,
} from '@squareup/dex-utils-docs-navigation';
import { usePathWithoutHash } from '@squareup/dex-utils-shared-routing-hooks';
import { useRouter } from 'next/router';
import React, {
  PropsWithChildren,
  FC,
  useMemo,
  useEffect,
  useCallback,
} from 'react';

type DocsBasePageProps = {
  contentfulEnvironment?: string;
  pageInView?: DocsPageInViewPayload;
  navSet?: DocsNavSetPayload;
  notFound: boolean;
  store: DocsStore;
  preview: boolean;
  releaseTrain?: string;
  didRedirect?: boolean;
};

/**
 * This page is a common component which is to be sub classed. Its not indended to be used as a stand alone page.
 * It provides the common loading and layout functionality used by all pages in docs. Its expected that a
 * subclass is to override
 *
 */

const DocsBasePage: FC<
  PropsWithChildren<{
    pageInView?: DocsPageInViewPayload;
    navSet?: DocsNavSetPayload;
    notFound: boolean;
    error?: SliceError;
    store: DocsStore;
    preview: boolean;
    didRedirect?: boolean;
  }>
> = ({
  children,
  pageInView,
  navSet,
  notFound,
  error,
  preview,
  didRedirect,
}) => {
  const pathWithoutHash = usePathWithoutHash();
  const { push } = useRouter();

  const prefetchOnMouseOver = usePrefetchPageOnMouseOver();

  const dispatch = useSharedDispatch();
  const toggleMobileNav = useCallback(() => {
    dispatch(toggleMobileNavIsOpen());
  }, [dispatch]);
  const toggleLeftNav = useCallback(() => {
    dispatch(toggleLeftNavIsCollapsed());
  }, [dispatch]);

  const docsDispatch = useDocsDispatch();

  const { isOpen: isMobileNavOpen } = useSelectMobileNavState();
  const { isCollapsed: isLeftNavCollapsed } = useSelectLeftNavState();

  // TODO: jguze - Remove when we introduce dark mode to /docs - https://block.atlassian.net/browse/DEX-11477
  useEffect(() => {
    if (document.documentElement.classList.contains('dark')) {
      document.documentElement.classList.remove('dark');
    }
  });

  useEffect(() => {
    // This value isn't set on SSR, so setting in after mount
    if (isDocsAdminToolsEnabled()) {
      docsDispatch(setIsPreview({ isPreview: preview }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!pageInView?.page?.slug) {
      return;
    }

    const relativeSlug = makeDocUrlRelative(pageInView?.page?.slug);

    if (
      didRedirect &&
      pageInView?.page?.slug &&
      makeDocUrlRelative(pathWithoutHash) !== relativeSlug
    ) {
      push(relativeSlug, undefined, {
        shallow: true,
      });
    }
  }, [didRedirect, pageInView?.page?.slug, pathWithoutHash, push]);

  const markdownContext = useMarkdownContext();
  markdownContext.extraNodes.push(fence);

  const linkContextProviderValue = useMemo(() => {
    return {
      persistedSearchParams: [
        {
          param: DocsQueryParams.preview,
          shouldUpdateHref: isDocsPageHref,
        },
        {
          param: DocsQueryParams.train,
          shouldUpdateHref: isDocsPageHref,
        },
        {
          param: DocsQueryParams.legacyUnpublishedOas,
          shouldUpdateHref: isDocsPageHref,
        },
        {
          param: DocsQueryParams.unpublishedOas,
          shouldUpdateHref: isDocsPageHref,
        },
        {
          param: DocsQueryParams.contentfulEnv,
          shouldUpdateHref: isDocsPageHref,
        },
      ],
    };
  }, []);

  const header = useMemo(() => {
    return <HeaderContainer slim={true} searchDomain="docs" />;
  }, []);

  const leftNav = useMemo(() => {
    return navSet?.value && pageInView?.page?.leftNavId ? (
      <LeftNavContainer
        navSet={navSet.value as NavSet}
        currentPath={pathWithoutHash}
        leftNavId={pageInView?.page?.leftNavId}
        toggleNav={toggleLeftNav}
        isCollapsed={isLeftNavCollapsed}
      />
    ) : null;
  }, [
    isLeftNavCollapsed,
    navSet?.value,
    pageInView?.page?.leftNavId,
    pathWithoutHash,
    toggleLeftNav,
  ]);

  const mobileNav = useMemo(() => {
    return (
      navSet?.value && (
        <MobileNav
          navSet={navSet.value as NavSet}
          onClose={toggleMobileNav}
          isOpen={isMobileNavOpen}
          leftNavId={pageInView?.page?.leftNavId}
          currentPath={pathWithoutHash}
        />
      )
    );
  }, [
    isMobileNavOpen,
    navSet?.value,
    pageInView?.page?.leftNavId,
    pathWithoutHash,
    toggleMobileNav,
  ]);

  const topNav = useMemo(() => {
    return (
      <TopNav
        navSet={navSet?.value}
        currentPath={pathWithoutHash}
        leftNavId={pageInView?.page?.leftNavId}
      />
    );
  }, [navSet?.value, pageInView?.page?.leftNavId, pathWithoutHash]);

  const footer = useCallback(() => {
    return <Footer wide={false} />;
  }, []);

  const contentsNode = useMemo(() => {
    let contents = children;
    if (notFound) {
      contents = <ErrorPage code={404} showLogo={false} />;
    }

    if (error) {
      contents = (
        <ErrorPage
          code={error.originalStatus || 500}
          showLogo={false}
          errorMessage={JSON.stringify(error, null, 4)}
        />
      );
    }

    return (
      <MarkdownContextProvider value={markdownContext}>
        {contents}
      </MarkdownContextProvider>
    );
  }, [children, error, markdownContext, notFound]);

  // Avoid re-rendering if nothing has changed.
  // This avoids issues where the hash would cause full re-renders
  const LayoutWrapper = useMemo(() => {
    return (
      <LinkContextProvider value={linkContextProviderValue}>
        <DocsPageAppLayout
          onMouseOver={prefetchOnMouseOver}
          Header={header}
          TopNavigation={topNav}
          LeftNavigation={leftNav}
          MobileNavigation={mobileNav}
          Footer={footer}
          contents={contentsNode}
          contentCenter={!pageInView?.page?.leftNavId || !navSet?.value}
          leftNavCollapsed={isLeftNavCollapsed}
        ></DocsPageAppLayout>
        <PreviewHandler />
      </LinkContextProvider>
    );
  }, [
    linkContextProviderValue,
    prefetchOnMouseOver,
    header,
    topNav,
    leftNav,
    mobileNav,
    footer,
    contentsNode,
    pageInView?.page?.leftNavId,
    navSet?.value,
    isLeftNavCollapsed,
  ]);

  return LayoutWrapper;
};

export { DocsBasePage, type DocsBasePageProps };
