import { RenderableTreeNode, Tag } from '@markdoc/markdoc';
import {
  MarkdownFrontmatter,
  doc,
} from '@squareup/dex-ui-shared-markdown-components';

import { transformMarkdown } from './Markdown';

type ParseFrontmatterOptions =
  | {
      markdown: string;
      nodes?: never;
    }
  | {
      markdown?: never;
      nodes: RenderableTreeNode[];
    };

function parseFrontmatterFromNodes(
  nodes: RenderableTreeNode[]
): MarkdownFrontmatter | null {
  if (nodes.length === 0) {
    return null;
  }

  if (Tag.isTag(nodes[0]) && nodes[0].name === doc.component?.name) {
    return nodes[0]?.attributes?.frontmatter || null;
  }
  return null;
}

/**
 * Parses the frontmatter from a MarkdownDocument node, if present
 * It does this without running all the other syntax transformers and components
 * in the markdown component
 * @param markdown A markdown string to parse and the transform
 * @param nodes A list of nodes that have already been transformed
 * @returns
 */
function parseFrontmatter({
  markdown,
  nodes,
}: ParseFrontmatterOptions): MarkdownFrontmatter | null {
  if (!nodes && !markdown) {
    return null;
  }

  const currentNodes = markdown
    ? [transformMarkdown(markdown, { disableAllComponents: true })]
    : nodes;
  if (currentNodes) {
    return parseFrontmatterFromNodes(currentNodes);
  }

  return null;
}

export { parseFrontmatter };
