import { DocsStoreState } from '@squareup/dex-shell-docs-core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useSelector } from 'react-redux';

const selectDocPageInView = (state: DocsStoreState) => {
  return state.docsShellViewState.pageInView.page;
};

const selectNavSet = (state: DocsStoreState) => {
  return state.docsShellViewState.navSet;
};

const selectIsPreview = (state: DocsStoreState) => {
  return state.docsShellViewState.adminFeature.isPreview;
};

const selectShowMarkdown = (state: DocsStoreState) => {
  return state.docsShellViewState.adminFeature.showMarkdown;
};

const selectReleaseTrain = (state: DocsStoreState) => {
  return state.docsShellViewState.adminFeature.releaseTrain;
};

const selectHtmlHighlight = (state: DocsStoreState) => {
  return state.docsShellViewState.adminFeature.htmlHighlight;
};

/* Selector hooks */
const useDocPageInView = () => useSelector(selectDocPageInView);
const useNavSet = () => useSelector(selectNavSet);
const useIsPreview = () => useSelector(selectIsPreview);
const useShowMarkdown = () => useSelector(selectShowMarkdown);
const useReleaseTrain = () => useSelector(selectReleaseTrain);
const useHtmlHighlight = () => useSelector(selectHtmlHighlight);

export {
  useDocPageInView,
  useNavSet,
  useIsPreview,
  useShowMarkdown,
  useReleaseTrain,
  useHtmlHighlight,
};
