import { DocsQueryParams } from '@squareup/dex-types-shared-docs';
import {
  getSlugFromDocsPage,
  splitParamsFromSlug,
} from '@squareup/dex-utils-docs-navigation';
import { MouseEventHandler, useCallback } from 'react';

import { loadDocPageInView } from './docs-actions';
import { useDocsDispatch } from './docs-dispatch';

/**
 * This returns an event listener for mouseover events that,
 * when it receives a mouseover, will check if it's an anchor,
 * and attempt to prefetch the docs page if valid.
 * To prefetch, we will run the RTKQ query, which will
 * cache a result for 60s by default
 * See https://redux-toolkit.js.org/rtk-query/usage/cache-behavior#default-cache-behavior for
 * more details
 * @returns A mouseover listener that prefetches docs pages on mouseover of anchors
 */
const usePrefetchPageOnMouseOver = () => {
  const dispatch = useDocsDispatch();

  return useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      const anchor = e.target as HTMLAnchorElement;
      if (anchor?.tagName !== 'A') {
        return;
      }

      const href = anchor.getAttribute('href');
      const slug = getSlugFromDocsPage(href);
      if (!slug) {
        return;
      }

      const { slug: strippedSlug, params } = splitParamsFromSlug(slug);

      const loadOptions: Pick<
        Parameters<typeof loadDocPageInView>[0],
        'preview' | 'releaseTrain' | 'contentfulEnvironment'
      > = {
        preview: false,
        releaseTrain: undefined,
        contentfulEnvironment: undefined,
      };

      // Read the params on the URL
      if (params.get(DocsQueryParams.preview) === 'true') {
        loadOptions.preview = true;
        params.delete(DocsQueryParams.preview);
      }

      const envParam = params.get(DocsQueryParams.contentfulEnv);
      if (envParam) {
        loadOptions.contentfulEnvironment = envParam;
        params.delete(DocsQueryParams.contentfulEnv);
      }

      const trainParam = params.get(DocsQueryParams.train);
      if (trainParam) {
        loadOptions.releaseTrain = trainParam;
        params.delete(DocsQueryParams.train);
      }

      const qspString =
        [...params.keys()].length > 0 ? `?${params.toString()}` : '';

      const finalSlug = `${strippedSlug}${qspString}`;

      // Load up the page pre-warmed. This will call RTKQ, which will
      // cache it. Then, when the user clicks it, RTKQ will return it
      // immediately
      dispatch(
        loadDocPageInView({
          query: { slug: finalSlug },
          stateless: true,
          ...loadOptions,
        })
      );
    },
    [dispatch]
  );
};

export { usePrefetchPageOnMouseOver };
