import {
  useIsPreview,
  useReleaseTrain,
} from '@squareup/dex-store-access-dex-tech-docs-selectors';
import React, { FC } from 'react';

import { DocsBanner } from './DocsBanner';

const PreviewHandler: FC = () => {
  const isPreview = useIsPreview();
  const releaseTrain = useReleaseTrain();

  if (isPreview) {
    return <DocsBanner variant="green">Previewing enabled</DocsBanner>;
  } else if (releaseTrain.inView) {
    return (
      <DocsBanner variant="blue">
        Release train - {releaseTrain.state?.slug}
      </DocsBanner>
    );
  }

  return null;
};

export { PreviewHandler };
